import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89')
];

export const server_loads = [0];

export const dictionary = {
		"/": [24],
		"/auth": [82],
		"/auth/verify-email": [83],
		"/chrome-extension": [84],
		"/error": [85],
		"/healthcheck/diagnose": [86],
		"/(login)/login": [25,[2]],
		"/ov-docs": [87],
		"/(login)/sign-up": [26,[2]],
		"/(login)/sign-up/hp-verify": [27,[2]],
		"/(user)/user-home": [28,[3]],
		"/(user)/user-profile": [29,[3,4]],
		"/(user)/user-profile/account": [30,[3,4]],
		"/(user)/user-profile/appearance": [31,[3,4]],
		"/(user)/user-profile/overview": [32,[3,4]],
		"/(user)/user-profile/profile": [33,[3,4]],
		"/(user)/verify-email": [34,[3]],
		"/zendesk_app": [88,[22]],
		"/zendesk_app/[community=slug]/copilot": [89,[22,23]],
		"/[community=slug]": [35,[5],[6]],
		"/[community=slug]/ai-history": [40,[5,8],[6]],
		"/[community=slug]/ai-history/[slug]": [41,[5,8],[6]],
		"/[community=slug]/beta-sandbox": [42,[5,9],[6]],
		"/[community=slug]/(knowledge-base)/category/[slug]/[uuid]": [36,[5,7],[6]],
		"/[community=slug]/changelog": [43,[5,10],[6]],
		"/[community=slug]/changelog/[slug]/[uuid]": [44,[5,10],[6]],
		"/[community=slug]/community/forum/[...rest]": [46,[5],[6]],
		"/[community=slug]/community/[...rest]": [45,[5],[6]],
		"/[community=slug]/content-library": [47,[5,11],[6]],
		"/[community=slug]/context-sources": [48,[5,12],[6]],
		"/[community=slug]/context-sources/external": [49,[5,12],[6]],
		"/[community=slug]/context-sources/snippets": [50,[5,12,13],[6]],
		"/[community=slug]/context-sources/snippets/[slug]/[uuid]": [51,[5,12,13],[6]],
		"/[community=slug]/demo": [52,[5,14],[6]],
		"/[community=slug]/(knowledge-base)/doc": [37,[5,7],[6]],
		"/[community=slug]/(knowledge-base)/doc/[slug]/[uuid]": [38,[5,7],[6]],
		"/[community=slug]/forums": [53,[5,15],[6]],
		"/[community=slug]/forums/community-error": [56,[5,15],[6]],
		"/[community=slug]/forums/home": [57,[5,15],[6]],
		"/[community=slug]/forums/members": [58,[5,15],[6]],
		"/[community=slug]/forums/page/[slug]/[uuid]": [59,[5,15],[6]],
		"/[community=slug]/forums/[forumSlug]/[uuid]": [54,[5,15,16],[6]],
		"/[community=slug]/forums/[forumSlug]/[uuid]/[threadSlug]/[threadUuid]": [55,[5,15,16],[6]],
		"/[community=slug]/home": [60,[5],[6]],
		"/[community=slug]/insights": [61,[5,17],[6]],
		"/[community=slug]/insights/knowledge-base": [~62,[5,17],[6]],
		"/[community=slug]/insights/workspace": [63,[5,17],[6]],
		"/[community=slug]/join": [64,[18]],
		"/[community=slug]/join/[inviteCode]": [65,[18]],
		"/[community=slug]/(knowledge-base)/section/[slug]/[uuid]": [39,[5,7],[6]],
		"/[community=slug]/settings": [66,[5,19],[6]],
		"/[community=slug]/settings/changelog/settings": [67,[5,19],[6]],
		"/[community=slug]/settings/community/custom-roles": [68,[5,19],[6]],
		"/[community=slug]/settings/community/labels": [69,[5,19],[6]],
		"/[community=slug]/settings/community/manage-members": [70,[5,19],[6]],
		"/[community=slug]/settings/community/settings": [71,[5,19],[6]],
		"/[community=slug]/settings/general/integrations": [72,[5,19],[6]],
		"/[community=slug]/settings/general/overview": [73,[5,19],[6]],
		"/[community=slug]/settings/general/team": [74,[5,19],[6]],
		"/[community=slug]/settings/general/webhooks": [75,[5,19],[6]],
		"/[community=slug]/settings/help-center/content-links": [76,[5,19],[6]],
		"/[community=slug]/settings/help-center/custom-domain": [77,[5,19],[6]],
		"/[community=slug]/settings/help-center/settings": [78,[5,19],[6]],
		"/[community=slug]/settings/widget/settings": [79,[5,19],[6]],
		"/[community=slug]/widget": [80,[20]],
		"/[community=slug]/workspace": [81,[5],[6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';